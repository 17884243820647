import { DetailPage } from "clariah/containers/DetailPage";
import { getStaticPathsForTypes } from "clariah/utils/staticHelpers";
import {
  HUB_OVERVIEW_TYPE,
  LANDING_TYPE,
  PAGE_TYPE,
} from "clariah/utils/constants";
import { NL, EN } from "shared/utils/constants";
import { fetchQuery, localize } from "clariah/utils/sanity";
import { undefinedToNull } from "shared/utils/undefinedToNull";
import { slugQuery as query } from "clariah/utils/staticHelpers";
import { HubOverviewPage } from "clariah/containers/HubOverviewPage";
import { LandingPage } from "clariah/containers/LandingPage";
import { LoadingPage } from "clariah/containers/LoadingPage";
import { useRouter } from "next/router";
import { createInternalHref } from "clariah/utils/createInternalHref";

export async function getStaticProps({
  preview = false,
  locale,
  params: { slug },
}) {
  const types = [HUB_OVERVIEW_TYPE, LANDING_TYPE, PAGE_TYPE];

  const data = await fetchQuery({
    preview,
    params: {
      slug,
      types,
    },
    query: query(locale),
  });

  data.mirrorPath = createInternalHref({
    href: data.mirrorPath,
    locale: locale === EN ? NL : EN,
    type: data._type,
    isOutOfScope: false,
  });

  if (!data?._type) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      preview,
      data: undefinedToNull(localize(data, locale)),
    },
  };
}

export async function getStaticPaths({ locales }) {
  return await getStaticPathsForTypes({
    locales: locales,
    types: [HUB_OVERVIEW_TYPE, LANDING_TYPE, PAGE_TYPE],
  });
}

const PageToExport = ({ data }) => {
  const { isFallback } = useRouter();

  if (isFallback) {
    return <LoadingPage />;
  }

  if (data._type === LANDING_TYPE) {
    return <LandingPage {...data} />;
  }

  if (data._type === HUB_OVERVIEW_TYPE) {
    return <HubOverviewPage {...data} />;
  }

  return <DetailPage {...data} />;
};

export default PageToExport;
